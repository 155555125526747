import axios from "axios";

import router from "@/router";
import { BaseUrl } from "./config";
import store from "../store";

const instance = axios.create({
  baseURL: BaseUrl,
  timeout: 10000,
});

instance.interceptors.request.use(
  (config) => {
    const token = store.getters.token;
    if (token) {
      config.headers.common["token"] = token;
    } else {
      store.commit("logout");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    let res = response.data;
    switch (res.code) {
      case 0:
      case 200:
        break;
      case 40002:
        alert("请重新登录");
        store.commit("logout");
        router.replace({
          path: "/",
          query: { rediret: router.currentRoute.fullPath },
        });
        break;
      case -1:
        break;
    }
    return response;
  },
  (error) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = "登录失败";
          break;
        case 401:
          error.message = "登录认证失败";
          store.commit("logout");
          router.replace({
            path: "/",
            query: { rediret: router.currentRoute.fullPath },
          });
          break;
        case 403:
          error.message = "无权限访问";
          break;
        case 404:
          error.message = "资源不存在";
          break;
        case 500:
          error.message = "服务器内部出错";
          break;
        case 502:
          error.message = "网关错误";
          break;
        default:
          error.message = "请求失败";
          break;
      }
    }

    if (error.response) {
      error.message += ":" + error.response.data.msg;
    }

    alert(error.message);
    return Promise.reject(error);
  }
);

const apiMethod = ["get", "post", "put", "delete"];
const api = {};

apiMethod.forEach((method) => {
  api[method] = (url, params, config) => {
    return new Promise((resolve) => {
      instance[method](url, params, config).then((res) => {
        if (res.status !== 200) {
          // 此处可以统一处理请求返回值
        } else {
          resolve(res);
        }
      });
    });
  };
});

export default api;
